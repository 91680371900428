<template>
  <el-card class="main-card">
    <template #header>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>用户管理</el-breadcrumb-item>
      </el-breadcrumb>
    </template>
    <el-form :model="querys" ref="querys" label-width="90px" class="search-form">
      <el-row>
        <el-form-item label="关键词" prop="keyword">
          <el-col>
            <el-input v-model="querys.keyword" prefix-icon="Search" @change="fetchs()"></el-input>
          </el-col>
        </el-form-item>

        <el-form-item label="所属公司" prop="companyID">
          <el-tree-select v-model="companyID" :data="companys"
            @change="departmentID = ''; companyChange(companyID); fetchs()" check-strictly :render-after-expand="false"
            style="min-width: 220px;" clearable />
        </el-form-item>

        <el-form-item label="所属部门" prop="departmentID">
          <el-tree-select v-model="departmentID" :data="departments" check-strictly @change="fetchs()"
            style="min-width: 120px;" :render-after-expand="false" clearable />
        </el-form-item>

        <el-form-item label="账户状态" prop="isEnabled">
          <!-- <el-switch v-model="isEnabled" active-color="#13ce66" inactive-color="#ff4949" active-text="启用"
            inactive-text="禁用" @change="fetchs()"></el-switch> -->
          <el-select v-model="isEnabled" clearable placeholder="" @change="fetchs()" style="min-width: 80px;">
            <el-option key="1" label="启用" value="1"></el-option>
            <el-option key="0" label="禁用" value="0"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="" label-width="20px">
          <el-button type="primary" icon="search" @click="fetchs()"> 搜索 </el-button>
        </el-form-item>
      </el-row>
    </el-form>

    <div class="toolbar">
      <el-button type="primary" icon="plus" @click="add()">新增</el-button>
      <el-button type="danger" icon="delete" @click="del()">删除</el-button>
      <el-button type="success" icon="Refresh" @click="reset()">重置密码</el-button>

      <el-button plain icon="Download" @click="fetchs('Export')">导出</el-button>
    </div>

    <static-table class="table" :pager="pager" :columns="querys.columns" :currHandler="currHandler"
      :sortHandler="sortHandler" :pageHandler="pageHandler" :sizeHandler="sizeHandler">
      <template v-slot:toolbar="scope">
        <el-button @click="edit(scope.row)" link size="small" type="primary">[编辑]</el-button>
        <el-button @click="openRole(scope.row)" link size="small" type="primary">[角色]</el-button>
      </template>
      <template v-slot:isEnabled="scope">
        <el-switch v-model="scope.row.isEnabled" active-color="#13ce66" inactive-color="#ff4949" active-text=""
          inactive-text="" :disabled="true">
        </el-switch>
      </template>
      <template v-slot:birthday="scope">
        <el-icon v-show="scope.row.birthday">
          <Calendar />
        </el-icon>
        <span style="margin-left: 10px">{{ (scope.row.birthday || "").split(" ")[0] }}</span>
      </template>
    </static-table>
  </el-card>

  <static-dialog ref="dialog" class="dialog" title="用户维护" :opened="opened" :ok="submit">
    <el-form :model="forms" :rules="rules" ref="forms" :label-width="labelWidth">
      <el-row :gutter="10">
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="所属公司" prop="companyID">
            <el-tree-select v-model="forms.companyID" :data="companys"
              @change="forms.departmentID = ''; companyChange(forms.companyID)" check-strictly
              :render-after-expand="false" />
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="所属部门" prop="departmentID">
            <!-- <el-select v-model="forms.parentID" placeholder="请选择上级菜单" disabled>
              <el-option label="根菜单" value=""></el-option>
            </el-select> -->
            <el-tree-select v-model="forms.departmentID" :data="departments" check-strictly
              :render-after-expand="false" />
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="用户账户" prop="account">
            <el-input v-model="forms.account" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="姓名" prop="realName">
            <el-input v-model="forms.realName" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="手机号" prop="mobile">
            <el-input v-model="forms.mobile" autocomplete="off"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="生日">
            <el-date-picker v-model="forms.birthday" type="date" placeholder="选择生日" format="YYYY-MM-DD"
              :editable="false">
            </el-date-picker>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-if="!forms.id">
          <el-form-item label="密码" prop="password">
            <el-input v-model="forms.password" placeholder="默认账户密码，登录时修改" autocomplete="off" show-password
              disabled></el-input>
          </el-form-item>
        </el-col>

        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="性别" class="gender">
            <el-radio v-model="forms.gender" label="男">男</el-radio>
            <el-radio v-model="forms.gender" label="女">女</el-radio>
            <el-radio v-model="forms.gender" label="无">无</el-radio>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="状态">
            <el-switch v-model="forms.isEnabled" active-color="#13ce66" inactive-color="#ff4949" active-text="启用"
              inactive-text="禁用" style="margin-left: 10px">
            </el-switch>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
          <el-form-item label="排序号">
            <el-input-number v-model="forms.sortCode" :min="1" :max="9999999" label="排序号"></el-input-number>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </static-dialog>

  <div class="dialog">
    <resetPwdDialog :visible="resetDialogVisible" :user="currRow"></resetPwdDialog>
  </div>

  <static-dialog ref="roleDialog" width="900px" class="dialog" title="用户角色分配" :ok="submitRole">
    <roleSelect ref="roleSelect"></roleSelect>
  </static-dialog>
</template>
<script>
import { fetchUsers, delUsers, submitUser, fetchRelationByGlobalId, submitRelationByGlobalId } from "@/api/sys";
import StaticDialog from "@/components/static-dialog.vue";
import StaticTable from "@/components/static-table.vue";
import resetPwdDialog from "@/views/sys/user/reset.vue";
import roleSelect from "@/views/sys/role/select.vue";
export default {
  name: "User",
  components: { StaticDialog, StaticTable, resetPwdDialog, roleSelect },
  data() {
    return {
      querys: {
        keyword: "",
        columns: [
          { type: "index", label: "序号" },
          { type: 'hidden', prop: "companyID", label: "公司ID", sortable: true, width: "110", },
          { type: 'hidden', prop: "departmentID", label: "部门ID", sortable: true, width: "110", },
          { prop: "account", label: "账户", sortable: true, searchable: true, width: "110", },
          { prop: "realName", label: "姓名", width: "110", sortable: true, searchable: true, },
          { prop: "mobile", label: "手机号", sortable: true, searchable: true, width: "110", },
          { prop: "gender", label: "性别", width: "90", sortable: true, searchable: true, },
          { type: "template", prop: "birthday", label: "生日", sortable: true, width: "120", },
          { type: "template", prop: "isEnabled", label: "状态", sortable: true, width: "90", },
          { prop: "sortCode", label: "排序号", sortable: true, width: "100", },
          { prop: "loginDate", label: "最后登录时间", width: "180" },
          { prop: "createDate", label: "创建时间", width: "180" },
          { type: "toolbar", label: "操作", width: "120" },
        ],
      },
      pager: {
        sidx: "loginDate", //默认的排序字段
        sord: "descending",
      },
      //multipleSelection: [], //多选的列
      currRow: null,

      forms: {},
      labelWidth: "90px",
      rules: {
        account: [
          { required: true, message: "请输入账户名称", trigger: "blur" },
        ],
        realName: [
          { required: true, message: "请输入用户姓名", trigger: "blur" },
        ],
        mobile: [
          { required: true, message: "请输入账户手机号", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确的手机号格式", trigger: "blur", },
        ],
        password: [
          { min: 4, max: 30, message: "密码长度不符合要求", trigger: "blur", },
        ],
      },
      resetDialogVisible: false,

      isEnabled: null,
      companyID: '',
      departmentID: '',

      departments: [],
      companys: [],
    };
  },
  mounted() {
    this.fetchs();

    let that = this;
    this.$kaung.companys().then((res) => {
      that.companys = res;
    });
  },
  methods: {
    //this.$refs.dialog.**
    edit(row) {
      this.forms = { ...row }; //JSON.parse(JSON.stringify(row));

      this.companyChange(this.forms.companyID);

      this.$refs.dialog.toggle();
    },
    add() {
      this.forms = { sortCode: 1, gender: "无" };

      this.$refs.dialog.toggle();
    },
    del() {
      let that = this;
      this.$confirm("此操作将删除本页选择数据, 是否继续?", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning", })
        .then(() => {
          delUsers(that.currRow.id).then((res) => {
            if (res.code === 200) {
              that.$message({
                type: "success",
                message: "删除成功",
              });
              that.fetchs();
            }
          });
        })
        .catch(() => {
          that.$message({ type: "info", message: "删除失败", });
        });
    },
    opened() {
      this.forms.sortCode = this.forms.sortCode || 1;
      this.forms.gender = this.forms.gender || "无";
      //懒渲染导致的BUG
      if (this.forms.birthday)
        this.forms.birthday = new Date(this.forms.birthday);
    },
    fetchs(methodName) {
      if ((typeof methodName) == 'object') methodName = null;
      //移除多余的参数
      //let { data, records, total, ...query } = { ...this.pager, ...params };
      let { data, records, total, ...search } = {
        ...this.pager,
        ...this.querys,
      };
      search.filters = [];
      if (this.companyID)
        search.filters.push({
          name: "companyId",
          value: this.companyID,
          operate: 0,
        });
      if (this.departmentID)
        search.filters.push({
          name: "departmentID",
          value: this.departmentID,
          operate: 0,
        });

      if (this.isEnabled)
        search.filters.push({
          name: "isEnabled",
          value: this.isEnabled,
          operate: 0,
        });

      search.method = methodName || '';
      fetchUsers(search).then((res) => {
        if (search.method == 'Export') {
          this.$notify({
            title: "导出成功",
            message: res.content,
            type: "success",
            duration: 10000
          });
          window.location.href = process.env.VUE_APP_BASEURL + res.data;
        }
        else {
          this.pager = { ...res.data };
        }
      });
    },
    submit() {
      let that = this;

      this.$refs.forms.validate((valid) => {
        if (!valid) return;

        submitUser(this.forms).then((res) => {
          if (res.code === 200) {
            that.$message({
              type: "success",
              message: res.content,
            });

            that.$refs.dialog.toggle();
            that.fetchs();
          }
        });
      });
    },
    currHandler(row) {
      this.currRow = row;
    },
    pageHandler(page) {
      this.pager.page = page;
      this.fetchs();
    },
    sizeHandler(rows) {
      this.pager.rows = rows;
      this.fetchs();
    },
    sortHandler(order) {
      this.pager.sidx = order.prop;
      this.pager.sord = order.order;
      this.fetchs();
    },

    reset() {
      if (this.currRow) {
        this.resetDialogVisible = true;
      } else {
        this.$message({
          type: "info",
          message: "请选择对应数据",
        });
      }
    },

    fetchRoleData(globalId) {
      var that = this;
      fetchRelationByGlobalId('user-role', globalId).then((res) => {
        that.checkedKeys = res.data;
        that.$refs.roleSelect.setCheckedKeys(res.data);
      });
    },
    openRole(row) {
      this.forms = { ...row };
      this.fetchRoleData(this.forms.id);
      this.$refs.roleDialog.toggle();
    },

    submitRole() {
      let checkArray = this.$refs.roleSelect.getSelectRows();
      // if (checkArray.length == 0) {
      //   this.$message({
      //     type: "info",
      //     message: "请勾选对应角色数据",
      //   });
      //   return;
      // }
      let roleArr = [];
      //checkArray.forEach(q => roleArr.push({ ObjectID: q.id }));
      checkArray.forEach(q => roleArr.push(q.id));

      submitRelationByGlobalId(roleArr, 'user-role', this.forms.id).then((res) => {
        if (res.code === 200) {
          this.$message({
            type: "success",
            message: res.content,
          });
        }
      });
    },


    companyChange(companyID) {
      if (!companyID) return;

      var that = this;
      this.$kaung.departments(companyID).then((res) => {
        that.departments = res;
      });
    }
  },
};
</script>
<style scoped>
.el-radio {
  margin-right: 10px;
}
</style>